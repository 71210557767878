<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.projects.projectDraws')}}</h4>
        <router-link
          :to="'/projectsWithdrawals/create?project_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-plus"></i> {{$t('components.projects.addition')}}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('projectsWithdrawals.searchForAnprojectsWithdrawalsOperations')"
        :emptyTableText="$t('projectsWithdrawals.thereAreNoprojectsWithdrawalsOperations')"
        :filter="{project_id: $route.params.id}"
        :emptyTableSubText="
          $t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')
        "
        :withoutCard="true"
        :cloumns="[
          {
            column: 'code',
            title: $t('projectsWithdrawals.operation'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'project_id',
            title: $t('projectsWithdrawals.projectName'),
            type: 'link',
            to: 'project',
            sort: true,
            link: true,
          },
          {
            column: 'store_id',
            title: $t('projectsWithdrawals.storeName'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true,
          },
          {
            column: 'date',
            title: $t('projectsWithdrawals.theDateOfTheOperation'),
            type: 'text',
            sort: true,
          },
          { column: 'total', title:$t('components.projects.total'), type: 'text', sort: true },
          {
            column: 'description',
            title: $t('projectsDeposits.thatIsAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'options',
            title: $t('projectsWithdrawals.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  $user.admin ||
                  $user.role.projects_withdrawals_edit,
              },
              { name: 'printprojectsWithdrawal', role: true },
              {
                name: 'delete',
                role:
                  $user.admin ||
                  $user.role.projects_withdrawals_delete,
              },
            ],
          },
        ]"
        :deleteText="{
          attention: $t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            $t('allerts.areYouReallySureToDeleteTheItem'),
          close: $t('allerts.close'),
          confirm: $t('allerts.confirm'),
          loading: $t('allerts.loading'),
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/projectsWithdrawals",
    };
  },
  components: {
    indexTable,
  },
};
</script>
