<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'chart-line'"
        :title="item.name"
        :buttons="[
          {
            title: $t('projects.show.modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $user.admin || $user.role.customers_edit,
          },
        ]"
      />

      <showCard
        class="col-12"
        :title="$t('projects.show.Background')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('projects.show.ProjectName'),
              value: item.name,
            },
            {
              icon: 'calendar',
              title: $t('projects.show.StartDate'),
              value: item.start_date,
            },
            {
              icon: 'dollar-sign',
              title: $t('projects.show.Balance'),
              value: $moneyFormat(item.balance + ' ' + $option.currency),
            },
            {
              icon: 'money-bill',
              title: $t('projects.show.ProjectedCost'),
              value: $moneyFormat(item.expected_cost + ' ' + $option.currency),
            },
            {
              icon: 'building',
              title: $t('projects.show.NumberOfUnits'),
              value: item.number_of_units,
            },
          ],
          scondCloum: [
            {
              icon: 'toggle-on',
              title: $t('projects.show.ProjectStatus'),
              value:
                item.stat == 1
                  ? $t('projects.show.effective')
                  : $t('projects.show.Ineffective'),
            },
            {
              icon: 'calendar',
              title: $t('projects.show.EndDate'),
              value: item.end_date,
            },
            {
              icon: 'dollar-sign',
              title: $t('projects.show.OpeningBalance'),
              value: item.opening_balance + ' ' + $option.currency,
            },
            {
              icon: 'money-bill-wave',
              title: $t('projects.show.ExpectedPrice'),
              value: item.expected_price + ' ' + $option.currency,
            },
          ],
        }"
      />

      <projectsDeposits class="col-12"  v-if="$site.projectsDeposits_allow && ($user.admin || $user.role.projects_deposits_show)"/>
      <projectsWithdrawals class="col-12"  v-if="$site.projectsWithdrawals_allow && ($user.admin || $user.role.projects_withdrawals_show)"/>
      <projectsTransfers class="col-12"  v-if="$site.projectsTransfers_allow && ($user.admin || $user.role.projects_transfers_show)"/>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import showHeader from "../elements/show/showHeader.vue";
import showCard from "../elements/show/showCard.vue";
import showBoxs from "../elements/show/showBoxs.vue";
import projectsDeposits from "./components/projectsDeposits.vue";
import projectsWithdrawals from "./components/projectsWithdrawals.vue";
import projectsTransfers from "./components/projectsTransfers.vue";

export default {
  data() {
    return {
      path: "/projects",
      loaded: false,
      item: {},
    };
  },
  mounted() {
    this.getitem();
  },
  methods: {
    getitem() {
      var item = this.$database.projects.find(
        (el) => el.id == this.$route.params.id
      );
      if (item) {
        this.item = item;
        this.item.start_date = new Date(item.start_date * 1000)
          .toISOString("en-US", { timeZone: "Africa/Cairo" })
          .slice(0, 10);
      }
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.loaded = true;
        });
    },
  },
  components: {
    showHeader,
    showCard,
    showBoxs,
    projectsDeposits,
    projectsTransfers,
    projectsWithdrawals,
  },
};
</script>
