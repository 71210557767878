var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"image":_vm.item.image,"icon":'chart-line',"title":_vm.item.name,"buttons":[
        {
          title: _vm.$t('projects.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$user.admin || _vm.$user.role.customers_edit,
        } ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('projects.show.Background'),"cloumns":{
        firstCloum: [
          {
            icon: 'user',
            title: _vm.$t('projects.show.ProjectName'),
            value: _vm.item.name,
          },
          {
            icon: 'calendar',
            title: _vm.$t('projects.show.StartDate'),
            value: _vm.item.start_date,
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('projects.show.Balance'),
            value: _vm.$moneyFormat(_vm.item.balance + ' ' + _vm.$option.currency),
          },
          {
            icon: 'money-bill',
            title: _vm.$t('projects.show.ProjectedCost'),
            value: _vm.$moneyFormat(_vm.item.expected_cost + ' ' + _vm.$option.currency),
          },
          {
            icon: 'building',
            title: _vm.$t('projects.show.NumberOfUnits'),
            value: _vm.item.number_of_units,
          } ],
        scondCloum: [
          {
            icon: 'toggle-on',
            title: _vm.$t('projects.show.ProjectStatus'),
            value:
              _vm.item.stat == 1
                ? _vm.$t('projects.show.effective')
                : _vm.$t('projects.show.Ineffective'),
          },
          {
            icon: 'calendar',
            title: _vm.$t('projects.show.EndDate'),
            value: _vm.item.end_date,
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('projects.show.OpeningBalance'),
            value: _vm.item.opening_balance + ' ' + _vm.$option.currency,
          },
          {
            icon: 'money-bill-wave',
            title: _vm.$t('projects.show.ExpectedPrice'),
            value: _vm.item.expected_price + ' ' + _vm.$option.currency,
          } ],
      }}}),(_vm.$site.projectsDeposits_allow && (_vm.$user.admin || _vm.$user.role.projects_deposits_show))?_c('projectsDeposits',{staticClass:"col-12"}):_vm._e(),(_vm.$site.projectsWithdrawals_allow && (_vm.$user.admin || _vm.$user.role.projects_withdrawals_show))?_c('projectsWithdrawals',{staticClass:"col-12"}):_vm._e(),(_vm.$site.projectsTransfers_allow && (_vm.$user.admin || _vm.$user.role.projects_transfers_show))?_c('projectsTransfers',{staticClass:"col-12"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }