<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.projects.transferProjects')}}</h4>
        <router-link
          :to="'/projectsTransfers/create?project_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-plus"></i> {{$t('components.projects.addition')}}
        </router-link>
      </div>

      <indexTable
        :searchText="$t('components.projects.searchForOperation')"
        :emptyTableText="$t('components.projects.thereAreNoOperations')"
        :emptyTableSubText="$t('components.projects.tryAddingAfterItemsAndTryAgain')"
        :filter="{project_id: $route.params.id}"
        :withoutCard="true"
        :cloumns="[
          { column: 'code', title: $t('components.projects.proces'), type: 'mainLink', sort: true },
          {
            column: 'from_project_id',
            title: $t('components.projects.fromProject'),
            type: 'link',
            to: 'fromProject',
            sort: true,
            link: true,
          },
          {
            column: 'to_project_id',
            title: $t('components.projects.toAProject'),
            type: 'link',
            to: 'toProject',
            sort: true,
            link: true,
          },
          {
            column: 'date',
            title: $t('components.projects.operationDate'),
            type: 'text',
            sort: true,
          },
          { column: 'total', title: $t('components.projects.total'), type: 'text', sort: true },
          {
            column: 'description',
            title: $t('components.projects.thatAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'options',
            title: $t('components.projects.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  $user.admin ||
                  $user.role.projects_deposits_edit,
              },
              { name: 'printProjectsDeposit', role: true },
              {
                name: 'delete',
                role:
                  $user.admin ||
                  $user.role.projects_deposits_delete,
              },
            ],
          },
        ]"
        :deleteText="{
          attention: $t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            $t('allerts.areYouReallySureToDeleteTheItem'),
          close: $t('allerts.close'),
          confirm: $t('allerts.confirm'),
          loading: $t('allerts.loading'),
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/projectsTransfers",
    };
  },
  components: {
    indexTable,
  },
};
</script>
